import { ref } from 'vue';
import { defineStore } from 'pinia';

import { LoginRequestBody, misB2BApi, ProfileFullResource, UserRoleEnum } from '~shared/api';
import { authStorageService, amplitudeService, clinicStorageService } from '~shared/lib';

type UserCredentialsData = {
  email: string;
  password: string;
};

type AppointmentDataForCurrentUser = {
  isActiveAppointment: boolean;
  appointmentId: number | null;
};

export const useSessionStore = defineStore('sessionStore', () => {
  const user = ref<ProfileFullResource | null>(null);
  const userCredentials = ref<UserCredentialsData | null>(null);
  const isAuthorized = ref(false);
  const userRole = ref<UserRoleEnum | null>(null);
  const appointmentDataForCurrentUser = ref<AppointmentDataForCurrentUser>({
    isActiveAppointment: false,
    appointmentId: null,
  });

  function setUserRole(payload: UserRoleEnum | null) {
    userRole.value = payload;
  }
  function setLoginUser(payload: ProfileFullResource) {
    user.value = payload;
    userCredentials.value = null;
    setUserRole(user.value.role);
    isAuthorized.value = true;
    amplitudeService.setUser(
      user.value.id,
      (clinicStorageService.getClinic()?.id && user.value.clinic_id) || user.value.clinic?.id
    );
    amplitudeService.logEvent('login');
  }

  function resetUser() {
    user.value = null;
    isAuthorized.value = false;
    setUserRole(null);
  }

  function setUserCredentials(payload: UserCredentialsData) {
    userCredentials.value = payload;
  }

  function setActiveAppointmentForCurrentUser(payload: AppointmentDataForCurrentUser) {
    appointmentDataForCurrentUser.value = {
      ...payload,
      isActiveAppointment: true,
    };
  }

  function closeActiveAppointmentForCurrentUser() {
    appointmentDataForCurrentUser.value = {
      isActiveAppointment: false,
      appointmentId: null,
    };
  }

  async function login(payload: LoginRequestBody) {
    const response = await misB2BApi.auth.login(payload);

    if (response?.data) {
      authStorageService.setAccessToken(response.data.access_token);
      authStorageService.setRefreshToken(response.data.refresh_token);
      authStorageService.setUser(response.data.user);
      clinicStorageService.setClinic(response.data.user.clinic);

      setLoginUser(response.data.user);
    }
  }

  async function checkAndSetUserAndTokenFromClientStorage() {
    const accessToken = authStorageService.getAccessToken();
    const refreshToken = authStorageService.getRefreshToken();
    if (!accessToken || !refreshToken) return;

    const userFromLocalStorage = authStorageService.getUser();
    if (userFromLocalStorage) {
      setLoginUser(userFromLocalStorage);
    }
    isAuthorized.value = true;
  }

  async function logout() {
    resetUser();
    authStorageService.removeTokens();
    authStorageService.removeUser();
    amplitudeService.removeUser();
    clinicStorageService.removeClinic();
  }

  function setUser(payload: ProfileFullResource) {
    user.value = payload;
  }

  return {
    user,
    isAuthorized,
    userRole,
    userCredentials,
    appointmentDataForCurrentUser,
    login,
    logout,
    checkAndSetUserAndTokenFromClientStorage,
    setUser,
    setUserCredentials,
    setActiveAppointmentForCurrentUser,
    closeActiveAppointmentForCurrentUser,
  };
});
