<template>
  <MiDialog
    width="1655px"
    class="salary-calculation-modal"
    :title="modalTitle"
    v-model="modalVisible">
    <div v-loading="loading">
      <SalaryDetailsForm
        v-if="modalVisible"
        :initial-values="formData"
        :loading="loading"
        :receiver-type="receiverType"
        :referrer="referrer"
        @updateEmployeeId="selectedRetrieverId = $event"
        @createSalary="handleCreateSalary" />
    </div>
    <PaymentConfirmModal
      @on-confirm="handleConfirmPayment"
      :amount="amountToBePaid"
      v-model="confirmModalVisible" />
  </MiDialog>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, shallowRef, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';

import { SalaryDetailsForm } from '../SalaryDetailsForm';
import { PaymentConfirmModal } from '../../../PaymentConfirmModal';

import { useSalaryStore } from '~entities/salary';
import { MiDialog } from '~shared/ui';
import { misB2BApi, ReceiverType, ReferrerResource, SalaryDetailResource } from '~shared/api';
import { SalaryDetails, SalaryFormData, ReceiverResource } from '~shared/types';
import { I18nService, amplitudeService } from '~shared/lib';

const props = defineProps<{
  modalVisible: boolean;
  salaryId?: number | null;
  receiverType: ReceiverType;
  referrer?: ReferrerResource | null;
  retriever?: ReceiverResource | null;
}>();

const emits = defineEmits<{
  'update:modalVisible': [event: boolean];
  'update:salaryId': [event: number | undefined | null];
  'submit:successful': [];
}>();

const salaryStore = useSalaryStore();
const { confirmModalVisible } = storeToRefs(salaryStore);
const loading = shallowRef(false);

const salaryId = computed({
  get() {
    return props.salaryId;
  },
  set(event) {
    emits('update:salaryId', event);
  },
});

const modalVisible = computed({
  get() {
    return props.modalVisible;
  },
  set(value) {
    emits('update:modalVisible', value);
  },
});

const selectedRetrieverId = ref<number | null>(null);
const { amountToBePaid } = storeToRefs(salaryStore);
const formData = ref<SalaryFormData>({} as SalaryFormData);
const modalTitle = computed(() =>
  props.receiverType === 'user'
    ? I18nService.t('Bookkeeping.CalculateSalaryFull')
    : I18nService.t('Referral.CalculatePayout')
);
const prepareSalaryDetails = (data: SalaryDetailResource[]): SalaryDetails[] => {
  return data.map((item) => {
    return {
      type: item.type,
      reason_type: item.reason_type,
      reason_id: item.reason_id,
      amount: item.amount,
      count: item.count,
      title: item.title,
      typeOperation: item.amount && item.amount > 0 ? 'plus' : 'minus',
    };
  });
};

const handleConfirmPayment = async () => {
  if (
    typeof salaryId.value === 'number' &&
    salaryId.value !== null &&
    salaryId.value !== undefined
  ) {
    const response = await misB2BApi.salaries.paidSalary(salaryId.value);

    if (response) {
      amplitudeService.logEvent('payout_salary', {
        salary_id: response.data.data.id,
      });
      confirmModalVisible.value = false;
      emits('submit:successful');
      modalVisible.value = false;
    }
  }
};

const getDetailsForSalary = async (id: number) => {
  loading.value = true;

  const response = await misB2BApi.salaries.getDetailsForSalary({
    [`${props.receiverType}_id`]: id,
  });

  loading.value = false;

  if (response && response?.data?.data && selectedRetrieverId.value !== null) {
    formData.value = {
      ...formData.value,
      retriever: {
        ...props.retriever,
        id: selectedRetrieverId.value,
      },
      salaryDetails: prepareSalaryDetails(response.data.data),
    };
  }
};

const getSalary = async (id: number) => {
  loading.value = true;
  const response = await misB2BApi.salaries.getSalary(id);

  if (response && response?.data?.data) {
    formData.value = {
      salary: response.data.data,
      salaryDetails: prepareSalaryDetails(response.data.data.salary_details),
      retriever: response.data.data.receiver,
    };
  }

  loading.value = false;
};

const handleCreateSalary = (id: number) => {
  salaryId.value = id;
  emits('submit:successful');
};

watch(
  () => props.salaryId,
  async (value) => {
    if (value) {
      await getSalary(value);
    }
  },
  {
    immediate: true,
  }
);

watch(selectedRetrieverId, async (value) => {
  if (value) {
    if (salaryId.value) return;

    await getDetailsForSalary(+value);
  }
});

onMounted(() => {
  if (props.retriever) {
    selectedRetrieverId.value = props.retriever.id;
    formData.value = {
      retriever: props.retriever,
    };
  }
});

onUnmounted(() => {
  emits('update:salaryId', null);
});
</script>

<style lang="scss" src="./index.scss"></style>
