<template>
  <FormSearchSelect
    field-name="retrieverId"
    :label-key="labelKey"
    field-object-name="retriever"
    :disabled="disabledEdit"
    :placeholder="searchPlaceholder"
    :fetch-data="getMethodForRetrieversList"
    @select="handleChangeRetriever" />
  <div class="salary-details-form__retriever">
    <EmployeeInfoCard v-if="receiverType === 'user'" :employee-id="retrieverId" />
    <ReferralSourceCard v-else :referral-data="referralData" />
  </div>

  <h3 class="salary-details-form__title">{{ $t('Bookkeeping.SalaryDetails') }}</h3>
  <FormFieldGroupWrapper>
    <div class="salary-details-form__group">
      <FormDatePicker
        class="salary-details-form__field"
        :label="$t('Bookkeeping.CalculationPeriod')"
        field-name="salary.calculation_period_start"
        :value-format="DATE_FORMAT"
        :disabled="disabledEdit"
        required />
      <FormDatePicker
        class="salary-details-form__field"
        field-name="salary.calculation_period_end"
        :disabled="disabledEdit"
        :value-format="DATE_FORMAT"
        required />
      <FormTextField
        class="salary-details-form__field"
        field-name="salary.description"
        :label="$t('Bookkeeping.PayoutComment')"
        :disabled="disabledEdit"
        :placeholder="$t('Bookkeeping.PayoutComment')" />
    </div>
  </FormFieldGroupWrapper>
  <FormFieldGroupWrapper>
    <div class="salary-details-form__group-calc">
      <SalaryDetailAdd @addOperation="addOperation" :disabled="disabledEdit" />
    </div>
    <SalaryDetailsTable
      :table-data="salaryDetails"
      :disabled="disabledEdit"
      @removeOperation="removeOperation" />

    <div class="salary-details-form__wrap-total">
      <span class="salary-details-form__total">{{ $t('Base.Total') }}</span>
      <span class="salary-details-form__summary">{{ paymentDetailsTotalSum }} сум</span>
    </div>
  </FormFieldGroupWrapper>
  <FormActionsWrapper align="right">
    <MiButton type="primary" :disabled="saveBtnDisabled" @click="handleCreateSalary">{{
      $t('Base.Save')
    }}</MiButton>
    <MiButton type="primary" @click="handlePaySalary" :disabled="payoutBtnDisabled">{{
      $t('Bookkeeping.PaySalary')
    }}</MiButton>
  </FormActionsWrapper>
</template>
<script lang="ts" setup>
import { watch, computed, ref, shallowRef, onMounted } from 'vue';
import { useField, useForm } from 'vee-validate';
import { object, string, number } from 'yup';
import { ElNotification } from 'element-plus';
import { storeToRefs } from 'pinia';

import { SalaryDetailsTable } from '../SalaryDetailsTable';
import { SalaryDetailAdd } from '../SalaryDetailAdd';

import { EmployeeInfoCard } from '~entities/employee';
import { ReferralSourceCard } from '~entities/referral';
import { useSalaryStore } from '~entities/salary/model/store';
import { misB2BApi, ReceiverType, ReferrerListResource, ReferrerResource } from '~shared/api';
import {
  FormDatePicker,
  FormFieldGroupWrapper,
  FormSearchSelect,
  FormTextField,
  FormActionsWrapper,
} from '~shared/ui/form';
import { DATE_FORMAT } from '~shared/config';
import { amplitudeService, I18nService } from '~shared/lib';
import { MiButton } from '~shared/ui';
import { SalaryDetails, SalaryFormData } from '~shared/types';

const props = defineProps<{
  initialValues?: SalaryFormData;
  loading?: boolean;
  receiverType: ReceiverType;
  referrer?: ReferrerResource | null;
}>();

const emits = defineEmits<{
  updateEmployeeId: [event: number];
  createSalary: [event: number];
}>();

const referralData = ref<ReferrerListResource | ReferrerResource>();
const salaryStore = useSalaryStore();
const { confirmModalVisible } = storeToRefs(salaryStore);
const { setAmountToBePaid } = salaryStore;
const disabledEdit = computed(() => !!props.initialValues?.salary?.id || props.loading);
const loadingSalary = shallowRef(false);

const labelKey = computed(() => {
  if (props.receiverType === 'referrer') {
    return 'title';
  } else {
    return props.initialValues?.salary?.id ? 'title' : 'name';
  }
});

const validationSchema = object({
  salary: object({
    calculation_period_start: string().required(I18nService.t('Validation.RequiredField')),
    calculation_period_end: string().required(I18nService.t('Validation.RequiredField')),
  }),
  retrieverId: number().nullable().required(I18nService.t('Validation.RequiredField')),
});

const { setValues, handleSubmit, errors, meta, isSubmitting, values } = useForm<SalaryFormData>({
  validationSchema,
});

const { value: salaryDetails } = useField<SalaryDetails[]>('salaryDetails');

const retrieverId = computed(() => values.retrieverId ?? null);

const saveBtnDisabled = computed(() =>
  Boolean(
    Object.keys(errors.value).length > 0 ||
      !meta.value.dirty ||
      isSubmitting.value ||
      !salaryDetails.value.length ||
      props.initialValues?.salary?.id ||
      props.initialValues?.salary?.status === 'paid' ||
      loadingSalary.value
  )
);

const payoutBtnDisabled = computed(() => {
  return Boolean(
    loadingSalary.value ||
      !props.initialValues?.salary?.id ||
      props.initialValues?.salary?.status === 'paid'
  );
});

const handleCreateSalary = handleSubmit(
  async (values) => {
    if (!values.salary || !values.retriever) return;

    const payload = {
      [`${props.receiverType}_id`]: values.retrieverId,
      calculation_period_start: values.salary.calculation_period_start ?? '',
      calculation_period_end: values.salary.calculation_period_end ?? '',
      description: values.salary.description,
      salary_details: salaryDetails.value.map((item) => {
        return {
          reason_type: item.reason_type ?? null,
          reason_id: item.reason_id ?? null,
          title: item.title,
          count: +item.count,
          amount: +item.amount,
        };
      }),
    };

    loadingSalary.value = true;
    const response = await misB2BApi.salaries.createSalary(payload);

    if (response) {
      emits('createSalary', response.data.data.id);
      amplitudeService.logEvent('create_salary', {
        salary_id: response.data.data.id,
      });
    }
    loadingSalary.value = false;
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors, ctx);
  }
);

const handlePaySalary = () => {
  setAmountToBePaid(paymentDetailsTotalSum.value ?? 0);
  confirmModalVisible.value = true;
};

const paymentDetailsTotalSum = computed(() => {
  return salaryDetails.value?.reduce((acc, curr) => {
    const amount = +curr.amount ?? 0;
    const count = +curr.count ?? 0;

    return acc + amount * count;
  }, 0);
});

const removeOperation = (row: SalaryDetails) => {
  salaryDetails.value = salaryDetails.value.filter((item) => {
    if (item.type) {
      return item.reason_id !== row.reason_id;
    } else {
      return item.title !== row.title;
    }
  });
  amplitudeService.logEvent('delete_salary_detail');
};

const checkDublicatedOperations = (title: string | null) => {
  return !salaryDetails.value.find((item) => item.title === title);
};

const addOperation = (data: SalaryDetails) => {
  if (checkDublicatedOperations(data.title)) {
    salaryDetails.value.push({
      ...data,
      amount: data.typeOperation === 'plus' ? data.amount : -data.amount,
    });
    amplitudeService.logEvent('add_salary_detail');
  } else {
    ElNotification({
      type: 'error',
      title: I18nService.t('Bookkeeping.MessageDublicateOperations'),
    });
  }
};

const getMethodForRetrieversList = computed(() =>
  props.receiverType === 'user' ? misB2BApi.usersTemp.getList : misB2BApi.referrers.getReferrers
);

const searchPlaceholder = computed(() =>
  props.receiverType === 'user'
    ? I18nService.t('Common.FIOEmployee')
    : I18nService.t('Referral.SourceName')
);

const handleChangeRetriever = (data: ReferrerListResource) => {
  if (props.receiverType === 'referrer') {
    referralData.value = data;
  }
};

watch(
  () => props.initialValues,
  (value) => {
    if (value) {
      setValues({
        salary: {
          calculation_period_start: value.salary?.calculation_period_start ?? '',
          calculation_period_end: value.salary?.calculation_period_end ?? '',
          description: value.salary?.description ?? '',
        },
        retrieverId: value.retriever?.id ?? null,
        retriever: value.retriever ?? null,
        salaryDetails: value.salaryDetails ?? [],
      });
    }
  },
  {
    immediate: true,
  }
);

watch(retrieverId, (value) => {
  if (!props.initialValues?.salary?.id && value) {
    emits('updateEmployeeId', value);
  }
});

onMounted(() => {
  if (props.referrer) {
    referralData.value = props.referrer;
    setValues({
      retrieverId: referralData.value?.id ?? null,
      retriever: {
        title: referralData.value?.title,
        id: referralData.value?.id,
      },
    });
  }
});
</script>

<style lang="scss" src="./index.scss"></style>
