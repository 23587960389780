<template>
  <teleport to="body">
    <div
      v-show="show"
      :class="['printer-document', { 'printer-document_full-page': fullPage }, $attrs.class]">
      <slot>
        <!--  Header  -->
        <div v-if="!hideHeader" class="printer-document-header printer-document__header">
          <slot name="header">
            <!--  Title  -->
            <div v-if="!hideTitle" :class="['printer-document-title', 'printer-document__title']">
              <slot name="title">
                <img
                  class="printer-document-title__logo"
                  :src="user?.clinic?.logo?.link"
                  alt="logo" />

                <div class="printer-document-title__text">
                  {{ title || `${user?.clinic?.specialization}  "${user?.clinic?.title}"` }}
                </div>

                <!--  Decree  -->
                <div v-show="!hideDecree" class="printer-document__decree">
                  <slot name="decree">
                    {{
                      decree ||
                      'Ozbekiston Respublikasi Sogliqni saqlash vazirining 2020 yil 31 dekabrdagi 363 - sonli buyrugi bilan tasdiqlangan'
                    }}
                  </slot>
                </div>
              </slot>
            </div>

            <!--  Address and Phones  -->
            <div v-if="!hideAddress" class="printer-document-address printer-document__address">
              <slot name="address"> {{ user?.clinic?.address }}, {{ clinicPhones }}</slot>
            </div>

            <!--  Meta  -->
            <div v-if="!hideMeta" class="printer-document-meta printer-document__meta">
              <slot name="meta">
                <div
                  class="printer-document-meta__part"
                  v-for="(part, index) in metaBlocks"
                  :key="index">
                  <PrinterDocumentContentBlock
                    v-for="block in part"
                    :key="block.uuid || block.label"
                    :block="block">
                    <slot :name="`meta-${block.uuid}`"></slot>

                    <template #value>
                      <slot :name="`meta-${block.uuid}-value`"></slot>
                    </template>
                  </PrinterDocumentContentBlock>
                </div>
              </slot>
            </div>
          </slot>
        </div>

        <!--  Content  -->
        <div class="printer-document-content printer-document__content">
          <!--  TODO: разобраться. Если contentBlocks пустой массив то появляется очень много страниц  -->
          <slot name="content">
            <PrinterDocumentContentBlock
              v-for="block in contentBlocks"
              :key="block.label"
              :block="block"
              :class="[
                'printer-document-content__block',
                { 'printer-document-content__block_inline': block.inline },
              ]">
              <slot :name="block.uuid"></slot>

              <template #value>
                <slot :name="`${block.uuid}-value`"></slot>
              </template>
            </PrinterDocumentContentBlock>
          </slot>
        </div>

        <!--  Append Content  -->
        <div class="printer-document-append-content printer-document__append-document">
          <slot name="append-content"></slot>
        </div>
      </slot>
    </div>
  </teleport>
</template>

<script>
import PrinterDocumentContentBlock from '@/components/printer/PrinterDocument/PrinterDocumentContentBlock/index.vue';
import { useSessionStore } from '~entities/session';

export default {
  name: 'PrinterDocument',
  components: { PrinterDocumentContentBlock },
  props: {
    decree: String,
    title: String,

    /** @type {Array< Array<PrinterDocumentContentBlock|object> >} metaBlocks */
    metaBlocks: Array,
    /** @type {Array<PrinterDocumentContentBlock|object>} content */
    contentBlocks: Array,

    hideHeader: Boolean,
    hideDecree: Boolean,
    hideClinic: Boolean,
    hideAddress: Boolean,
    hideTitle: Boolean,
    hideMeta: Boolean,

    fullPage: Boolean,
  },
  data() {
    return {
      show: false,
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    clinicPhones() {
      return this.user?.clinic?.phones?.join(', ');
    },
    user() {
      return this.sessionStore.user;
    },
  },
  methods: {
    print() {
      this.show = true;

      // если будут какие-то вычисления, наподобии генерации qr кода
      return new Promise((resolve) => {
        setTimeout(async () => {
          await window.print();
          this.show = false;

          resolve();
        }, 1000);
      });
    },
    checkScroll() {
      const header = document.querySelector('.printer-document__header');

      const scrollPosition = window.scrollY;

      if (scrollPosition > 0) {
        header.classList.add('has-scroll');
      } else {
        header.classList.remove('has-scroll');
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.checkScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScroll);
  },
};
</script>

<style lang="scss" src="./index.scss" />
