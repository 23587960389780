/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuthResource {
  access_token: string;
  refresh_token: string;
  token_type: 'bearer';
  user: ProfileFullResource;
}

export interface ProfileFullResource {
  id: number;
  humId: string;
  name: string;
  email: string | null;
  phone: string | null;
  birthdate: string | null;
  gender: string | null;
  /** @format date */
  created_at: string;
  role: UserRoleEnum;
  childrens: ProfileShortResource[];
  latitude: number | null;
  longitude: number | null;
  city: CityResource;
  district?: DistrictResource;
  parent_id: number | null;
  parent: ProfileShortResource;
  age: number | null;
  doctor_id: number | null;
  doctor: DoctorShortResource;
  place_residence: string | null;
  has_treatment: boolean | null;
  clinic_id: number | null;
  clinic: ClinicShortResource;
  files: FileResource[];
  files_ids: number[] | null;
  is_foreign: boolean | null;
  microdistrict: MicrodistrictResource;
  pinfl: string | null;
  passport_serial: string | null;
  passport_number: string | null;
  group: string | null;
  /** @format date */
  died_at: string | null;
  disease_codes: DiseaseCodeResource[];
}

export interface ProfileShortResource {
  id: number;
  humId: string;
  name: string;
  email: string | null;
  phone: string | null;
  /** @format date */
  birthdate: string | null;
  gender: UserGenderEnum;
  /** @format date */
  created_at: string;
  role: UserRoleEnum;
  childrens_count: number;
  childrens: ProfileShortResource[];
  parent_id: number | null;
  age: number | null;
  doctor_id: number | null;
  doctor: DoctorShortResource;
  has_treatment: boolean | null;
  place_residence: string | null;
  country: string | null;
  is_foreign: boolean | null;
}

export interface ProfileNameResource {
  id: number;
  name: string;
  phone: string;
  humId: string;
}

export interface AnalysisFullResource {
  id: number;
  title: string;
  title_ru: string;
  title_uz: string;
  name: string;
  measure: string;
  biomaterial_type: BiomaterialTypeEnum;
  /** @format date */
  created_at: string;
  group_services: GroupServiceResource[];
  group_service_ids?: number[];
  biomaterial_type_translate: string;
  value_type: string | null;
  category: string | null;
}

export interface GroupServiceResource {
  id: number;
  clinic: ClinicShortResource;
  clinic_id: number;
  services: ServiceShortResource[];
  category_id: number;
  /** @min 0 */
  total_amount: number;
  /** @format date */
  created_at: string;
  doctors: DoctorShortResource[];
  doctor_ids: number[];
  title: string;
  title_ru: string | null;
  title_en: string | null;
  title_uz: string | null;
  package_code: string | null;
  IKPU_CODE: string | null;
  vat_percent: number | null;
  /** @min 0 */
  price: number;
  slot_duration: number | null;
  is_published: boolean | null;
  category: ServiceCategoryShortResource;
  market_calculations: GroupServiceMarketCalculations;
}

export interface ServiceShortResource {
  id: number;
  title: string;
  doctor: DoctorShortResource;
  doctor_id: number;
  slot_duration: number;
  price: number;
  /** @format date */
  created_at: string;
}

export interface DoctorShortResource {
  id: number;
  name: string;
  avatar: FileResource;
  avatar_id: number;
  clinic: ClinicShortResource;
  clinic_id: number;
  specialties: SpecialtyResource[];
  /** @format date */
  published_at: string | null;
  /** @format date */
  created_at: string;
  cabinet: number;
  sign_id: number | null;
  sign: FileResource;
}

export interface ClinicShortResource {
  id: number;
  title: string;
  address: string | null;
  city: CityResource;
  city_id: number | null;
  district: DistrictResource;
  district_id: number | null;
  logo: FileResource;
  logo_id: number | null;
  /** @format date */
  published_at: string | null;
  /** @format date */
  created_at: string;
  specialization: string | null;
  phones: string[];
}

export interface CityResource {
  id: number;
  title: string;
}

export interface DistrictResource {
  id: number;
  title: string;
}

export interface ServiceCategoryShortResource {
  id: number;
  title: string;
}

export interface FileResource {
  id: number;
  link: string;
  filename: string | null;
  size: number | null;
}

export interface MicrodistrictResource {
  id: number;
  title: string;
  nurses: NurseResource[];
}

export interface NurseResource {
  id: number;
  name: string;
}

export interface DepartmentResource {
  id: number;
  title: string;
  /** @format datetime */
  created_at: string;
}

export interface DiseaseCodeResource {
  code: string;
  title: string;
}

export interface SalaryResource {
  id: number;
  type: SalaryType;
  amount: number;
  status: SalaryStatus;
  /** @format date */
  paid_at: string | null;
  description: string | null;
  /** @format date */
  calculation_period_start: string | null;
  /** @format date */
  calculation_period_end: string | null;
  /** @format date */
  created_at: string;
}

export interface ReceiverResource {
  id: number;
  title: string;
  type?: ReceiverType;
}

export interface SalaryDetailResource {
  id: number;
  type: SalaryDetailType;
  reason_id: number | null;
  reason_type: string | null;
  amount: number;
  count: number;
  title: string | null;
}

export interface SalaryStatisticsResource {
  not_paid_salaries: {
    amount_sum: number;
    count: number;
  };
  salaries: {
    amount_sum: number;
    count: number;
  };
  down_payments: {
    amount_sum: number;
    count: number;
  };
}

export interface EmployeeSalaryResource {
  id: number;
  name: string;
  /** @format date */
  last_paid_calculation_period_start: string | null;
  /** @format date */
  last_paid_calculation_period_end: string | null;
  not_paid_commissions: number;
  not_paid_down_payments: number;
}

export interface ReferrerShortResource {
  id: number;
  title: string;
  uuid: number;
}

export interface ReferrerListResource {
  id: number;
  title: string;
  recommendation_commission: number | null;
  phone: string | null;
  description: string | null;
  total_amount: number;
  not_paid_commissions: number;
  count_invoices: number;
  uuid: number,
  charge_commission: boolean
}

export interface ReferrerResource {
  id: number;
  title: string;
  phone: string | null;
  description: string | null;
  recommendation_commission: number | null;
  uuid: number;
  charge_commission: boolean | null;
}

export interface GroupServiceTitleResource {
  id: number;
  title: string;
  price: number;
}

export interface ReferrerGroupServiceResource {
  group_service: GroupServiceTitleResource;
  commission: number;
}

export interface CommissionResource {
  id: number;
  type: CommissionType;
  amount: number;
  status: SalaryStatus;
  /** @format date */
  created_at: string;
  payment_subject_title: string;
  /** Может быть null для старых комиссий у которых цена не заполнялась */
  payment_subject_price: number | null;
  invoice_id: number;
}

export interface InvoiceCommissionService {
  title: string;
  price: number;
  commission: number;
}

export interface AppointmentCalendarResource {
  id: number;
  /** @format date */
  start_at: string | null;
  /** @format date */
  end_at: string | null;
  status: AppointmentStatusEnum;
  doctor_id: number;
}

export interface AppointmentDoctorCalendarResource {
  /** @format datetime */
  date: string;
}

export interface DoctorResource {
  id: number;
  name: string;
}

export interface InvoiceStatusResource {
  id: number;
  status: string;
}

export interface WorkingTimeResource {
  /** @format time */
  monday_start: string | null;
  /** @format time */
  monday_end: string | null;
  /** @format time */
  tuesday_start: string | null;
  /** @format time */
  tuesday_end: string | null;
  /** @format time */
  wednesday_start: string | null;
  /** @format time */
  wednesday_end: string | null;
  /** @format time */
  thursday_start: string | null;
  /** @format time */
  thursday_end: string | null;
  /** @format time */
  friday_start: string | null;
  /** @format time */
  friday_end: string | null;
  /** @format time */
  saturday_start: string | null;
  /** @format time */
  saturday_end: string | null;
  /** @format time */
  sunday_start: string | null;
  /** @format time */
  sunday_end: string | null;
}

export interface SpecialtyResource {
  id: number;
  title: string;
}

export interface PatientNameResource {
  id: number;
  name: string;
}

export type ChronologyResource =
  | (AnalysisChronologyResource & {
      doctor?: DoctorShortResource;
      group_services?: GroupServiceResource[];
    })
  | (AppointmentChronologyResource & {
      doctor?: DoctorShortResource;
      group_services?: GroupServiceResource[];
      disease_codes?: DiseaseCodeResource[];
    })
  | (HospitalChronologyResource & {
      doctor?: DoctorShortResource;
      department?: DepartmentResource;
    });

export interface AnalysisChronologyResource {
  id: number;
  type: 'analysis';
  /** @format date */
  date: string;
  /** @format time */
  time: string;
  status: OrderStatusEnum;
}

export interface AppointmentChronologyResource {
  id: number;
  type: 'appointment';
  /** @format date */
  date: string;
  /** @format time */
  time: string;
}

export interface HospitalChronologyResource {
  id: number;
  type: 'hospital';
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string | null;
  status: HospitalStatusEnum;
}

export interface GroupServiceReferrerResource {
  id: number;
  title?: string;
  price: number;
  commission: number | null;
}

export interface PriceListResource {
  id: number;
  title?: string;
}

export interface LoginRequestBody {
  /** @format email */
  email: string;
  password: string;
  /** @format xxxxxx */
  key_activating?: string | null;
}

export interface RescheduleAppointment {
  doctor_id?: number;
  /** @format date-time */
  start_at: string;
  /** @format date-time */
  end_at: string;
}

/** Один из параметров user_id, referrer_id обязательно должен присутствовать */
export interface CreateSalary {
  referrer_id?: number | null;
  user_id?: number | null;
  description?: string | null;
  /** @format date */
  calculation_period_start: string;
  /** @format date */
  calculation_period_end: string;
  salary_details: SalaryDetailRequest[];
}

export interface SalaryDetailRequest {
  reason_type: string | null;
  reason_id: number | null;
  title: string | null;
  count: number;
  amount: number;
}

export interface CreateDownPaymentRequest {
  user_id: number;
  amount: number;
  description?: string | null;
}

export interface CreateReferrer {
  title: string;
  phone?: string | null;
  description?: string | null;
  recommendation_commission?: number | null;
  user_id?: number | null;
  uuid?: number;
  group_services?:
    | {
        group_service_id: number;
        commission: number;
      }[]
    | null;
  charge_commission?: boolean | null;
}

export interface UpdateReferrer {
  title: string;
  phone?: string | null;
  description?: string | null;
  recommendation_commission?: number | null;
  uuid?: number;
  group_services?:
    | {
        group_service_id: number;
        commission: number;
      }[]
    | null;
  charge_commission?: boolean | null;
}

export type AppointmentTypeEnum = 'default' | 'daily' | 'instant';

export type AppointmentStatusEnum =
  | 'created'
  | 'wait_for_payment'
  | 'wait_for_doctor_approve'
  | 'approved'
  | 'canceled'
  | 'provided'
  | 'in_progress'
  | 'waiting'
  | 'on_the_way';

export type UserGenderEnum = 'man' | 'women';

export type UserRoleEnum =
  | 'patient'
  | 'admin'
  | 'super-admin'
  | 'manager'
  | 'consultant'
  | 'doctor'
  | 'family-doctor'
  | 'director'
  | 'laboratory'
  | 'nurse'
  | 'warehouse'
  | 'accountant'
  | 'registry';

export type BiomaterialTypeEnum =
  | 'urine'
  | 'blood'
  | 'feces'
  | 'sperm'
  | 'prostate_secret'
  | 'urethral_smear'
  | 'female_smear'
  | 'nasal_smear'
  | 'any_smear'
  | 'scraping'
  | 'sputum'
  | 'breast_milk'
  | 'spinal_fluid'
  | 'unknown';

export type SalaryType = 'salary' | 'down_payment';

export type SalaryStatus = 'not_paid' | 'paid';

export type ReceiverType = 'user' | 'referrer';

export type SalaryDetailType = 'appointment' | 'recommendation' | 'down_payment';

export type CommissionType = 'appointment' | 'recommendation';

export type OrderStatusEnum = 'created' | 'assemble' | 'awaiting_confirm' | 'ready';

export type HospitalStatusEnum = 'in_progress' | 'completed' | 'canceled';

/** Метаданные для ответов с пагинацией */
export interface PaginationMeta {
  per_page: number;
  total: number;
  current_page: number;
  last_page: number;
  from: number;
  to: number;
}

export type GroupServiceMarketCalculations = {
  average_market_price: number | null;
  market_demand: number | null;
  optimal_margin: number | null;
  optimal_price: number | null;
  market_price_range_from: number | null;
  market_price_range_to: number | null;
  cost_price: number | null;
  margin: number | null;
};

export interface ErrorResource {
  message?: string | null;
}

export interface GetSalariesParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  status?: SalaryStatus;
  type?: SalaryType;
}

export interface GetSalariesByEmployeesParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  /** @default false */
  not_paid_commissions?: boolean;
  /** @default false */
  not_paid_down_payments?: boolean;
}

export interface GetSalaryStatisticsParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  status?: SalaryStatus;
  type?: SalaryType;
}

export interface GetDetailsForSalaryParams {
  user_id?: number | null;
  referrer_id?: number | null;
}

export interface GetReferrersParams {
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
}

export interface CheckIdReferrerParams {
  uuid: number;
}

export interface GetReferralSalariesParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  status?: SalaryStatus;
}

export interface GetReferrersCommissionsParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  status?: SalaryStatus;
  not_paid_invoice?: boolean;
  type?: CommissionType;
  referrerId: number;
}

export interface CalendarAppointmentParams {
  /** @format datetime */
  date: string;
  doctor_ids?: number[];
}

export interface DoctorCalendarAppointmentParams {
  /** @format datetime */
  start_date: string;
  /** @format datetime */
  end_date: string;
  doctor_id?: number;
}

export interface GetChronologyParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  userId: number;
}

export interface GetAppointmentsParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  userId: number;
}

export interface GetAnalysesParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  userId: number;
}

export interface GetHospitalsParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  userId: number;
}

export interface GetPriceListsParams {
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
}

export interface ExportSalariesParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  status?: SalaryStatus;
  type?: SalaryType;
}

export type ExportSalariesData = any;

export interface GroupServiceListForReferrersParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  type: 'analysis' | 'consultation' | 'other';
  referrer_id?: number;
  category_id?: number;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://stage.zordoc.uz/api/mis',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title ZorDoc B2B
 * @version 1.0.0
 * @baseUrl https://stage.zordoc.uz/api/mis
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Авторизация
     * @name Login
     * @summary Авторизировать пользователя
     * @request POST:/auth/login
     * @secure
     */
    login: (data: LoginRequestBody, params: RequestParams = {}) =>
      this.request<AuthResource, ErrorResource>({
        path: `/auth/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name RefreshToken
     * @summary Обновить пару токенов access и refresh
     * @request POST:/auth/refresh
     * @secure
     */
    refreshToken: (
      data: {
        refresh_token: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<AuthResource, any>({
        path: `/auth/refresh`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),
  };
  clinics = {
    /**
     * No description
     *
     * @tags Клиники
     * @name GetClinicWorkingTime
     * @summary Получить время работы текущей клиники
     * @request GET:/clinics/working-time
     * @secure
     */
    getClinicWorkingTime: (params: RequestParams = {}) =>
      this.request<
        {
          data: WorkingTimeResource;
        },
        void
      >({
        path: `/clinics/working-time`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  salaries = {
    /**
     * No description
     *
     * @tags Зарплаты
     * @name GetSalaries
     * @summary Получить список зарплат
     * @request GET:/salaries
     * @secure
     */
    getSalaries: (query: GetSalariesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (SalaryResource & {
            receiver: ReceiverResource;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/salaries`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Зарплаты
     * @name GetSalariesByEmployees
     * @summary Получить список зарплат по сотрудникам
     * @request GET:/salaries/employees
     * @secure
     */
    getSalariesByEmployees: (query: GetSalariesByEmployeesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: EmployeeSalaryResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/salaries/employees`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Зарплаты
     * @name GetSalaryStatistics
     * @summary Получить статистику по зарплатам
     * @request GET:/salaries/statistics
     * @secure
     */
    getSalaryStatistics: (query: GetSalaryStatisticsParams, params: RequestParams = {}) =>
      this.request<SalaryStatisticsResource, ErrorResource>({
        path: `/salaries/statistics`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Один из параметров user_id, referrer_id обязательно должен присутствовать
     *
     * @tags Зарплаты
     * @name GetDetailsForSalary
     * @summary Получить детализацию для зарплаты
     * @request GET:/salaries/details
     * @secure
     */
    getDetailsForSalary: (query: GetDetailsForSalaryParams, params: RequestParams = {}) =>
      this.request<
        {
          data: SalaryDetailResource[];
        },
        ErrorResource
      >({
        path: `/salaries/details`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Зарплаты
     * @name GetSalary
     * @summary Получить зарплату по её id
     * @request GET:/salaries/{salary-id}
     * @secure
     */
    getSalary: (salaryId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: SalaryResource & {
            receiver: ReceiverResource;
            salary_details: SalaryDetailResource[];
          };
        },
        ErrorResource
      >({
        path: `/salaries/${salaryId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Зарплаты
     * @name CreateSalary
     * @summary Создать зарплату
     * @request POST:/salaries/create
     * @secure
     */
    createSalary: (data: CreateSalary, params: RequestParams = {}) =>
      this.request<
        {
          data: SalaryResource & {
            receiver: ReceiverResource;
            salary_details: SalaryDetailResource[];
          };
        },
        ErrorResource
      >({
        path: `/salaries/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Зарплаты
     * @name CreateDownPayment
     * @summary Создать аванс
     * @request POST:/salaries/create/down-payment
     * @secure
     */
    createDownPayment: (data: CreateDownPaymentRequest, params: RequestParams = {}) =>
      this.request<
        {
          data: SalaryResource & {
            receiver: ReceiverResource;
          };
        },
        ErrorResource
      >({
        path: `/salaries/create/down-payment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Зарплаты
     * @name PaidSalary
     * @summary Выплатить зарплату
     * @request PUT:/salaries/{salary-id}/paid
     * @secure
     */
    paidSalary: (salaryId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: SalaryResource & {
            receiver: ReceiverResource;
          };
        },
        ErrorResource
      >({
        path: `/salaries/${salaryId}/paid`,
        method: 'PUT',
        secure: true,
        ...params,
      }),
  };
  referrers = {
    /**
     * No description
     *
     * @tags Реферальные источники
     * @name GetReferrers
     * @summary Получить лист реф источников
     * @request GET:/referrers
     * @secure
     */
    getReferrers: (query: GetReferrersParams, params: RequestParams = {}) =>
      this.request<
        {
          data: ReferrerListResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/referrers`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реферальные источники
     * @name CheckIdReferrer
     * @request GET:/referrers/check-id
     * @secure
     */
    checkIdReferrer: (query: CheckIdReferrerParams, params: RequestParams = {}) =>
      this.request<
        {
          data: ReferrerListResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/referrers/check-id`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Зарплаты
     * @name GetReferralSalaries
     * @summary Получить список реферальных начислений
     * @request GET:/referrers/salaries
     * @secure
     */
    getReferralSalaries: (query: GetReferralSalariesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (SalaryResource & {
            receiver: ReceiverResource;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/referrers/salaries`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реферальные источники
     * @name GetReferrer
     * @summary Получить реф источник по id
     * @request GET:/referrers/{referrer-id}
     * @secure
     */
    getReferrer: (referrerId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: ReferrerResource & {
            group_services: ReferrerGroupServiceResource[];
            user: PatientNameResource;
          };
        },
        ErrorResource
      >({
        path: `/referrers/${referrerId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реферальные источники
     * @name UpdateReferrer
     * @summary создать реф источник
     * @request PUT:/referrers/{referrer-id}
     * @secure
     */
    updateReferrer: (referrerId: number, data: UpdateReferrer, params: RequestParams = {}) =>
      this.request<
        {
          data: ReferrerResource & {
            group_services: ReferrerGroupServiceResource[];
            user: PatientNameResource;
          };
        },
        ErrorResource
      >({
        path: `/referrers/${referrerId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реферальные источники
     * @name DeleteReferrer
     * @summary создать реф источник
     * @request DELETE:/referrers/{referrer-id}
     * @secure
     */
    deleteReferrer: (referrerId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: ReferrerResource & {
            group_services: ReferrerGroupServiceResource[];
            user: PatientNameResource;
          };
        },
        ErrorResource
      >({
        path: `/referrers/${referrerId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реферальные источники
     * @name GetReferrersCommissions
     * @summary Получить список комиссий
     * @request GET:/referrers/{referrerId}/commissions
     * @secure
     */
    getReferrersCommissions: (
      { referrerId, ...query }: GetReferrersCommissionsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: CommissionResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
          amount_sum: number;
        },
        ErrorResource
      >({
        path: `/referrers/${referrerId}/commissions`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Реферальные источники
     * @name CreateReferrer
     * @summary создать реф источник
     * @request POST:/referrers/create
     * @secure
     */
    createReferrer: (data: CreateReferrer, params: RequestParams = {}) =>
      this.request<
        {
          data: ReferrerResource & {
            group_services: ReferrerGroupServiceResource[];
            user: PatientNameResource;
          };
        },
        ErrorResource
      >({
        path: `/referrers/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  appointments = {
    /**
     * No description
     *
     * @tags Приемы
     * @name RescheduleAppointment
     * @summary Перенести приём
     * @request PUT:/appointments/{appointmentId}/reschedule
     * @secure
     */
    rescheduleAppointment: (
      appointmentId: number,
      data: RescheduleAppointment,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: AppointmentCalendarResource & {
            invoice: InvoiceStatusResource;
            patient: PatientNameResource;
          };
        },
        any
      >({
        path: `/appointments/${appointmentId}/reschedule`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Приемы
     * @name CalendarAppointment
     * @summary Получить приемы для календаря клиники
     * @request GET:/appointments/calendar
     * @secure
     */
    calendarAppointment: (query: CalendarAppointmentParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (DoctorResource & {
            working_time: WorkingTimeResource;
            specialties: SpecialtyResource[];
            appointments: (AppointmentCalendarResource & {
              invoice: InvoiceStatusResource;
              patient: PatientNameResource;
            })[];
          })[];
        },
        ErrorResource
      >({
        path: `/appointments/calendar`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Приемы
     * @name DoctorCalendarAppointment
     * @summary Получить приемы для календаря клиники
     * @request GET:/appointments/doctor-calendar
     * @secure
     */
    doctorCalendarAppointment: (
      query: DoctorCalendarAppointmentParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (AppointmentDoctorCalendarResource & {
            appointments: (AppointmentCalendarResource & {
              invoice: InvoiceStatusResource;
              patient: PatientNameResource;
            })[];
          })[];
        },
        ErrorResource
      >({
        path: `/appointments/doctor-calendar`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  patients = {
    /**
     * No description
     *
     * @tags Медкарта
     * @name GetChronology
     * @summary Получить данные для хронологии в медкарте
     * @request GET:/patients/{userId}/chronology
     * @secure
     */
    getChronology: ({ userId, ...query }: GetChronologyParams, params: RequestParams = {}) =>
      this.request<
        {
          data: ChronologyResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/patients/${userId}/chronology`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Медкарта
     * @name GetAppointments
     * @summary Получить данные для осмотров в медкарте
     * @request GET:/patients/{userId}/appointments
     * @secure
     */
    getAppointments: ({ userId, ...query }: GetAppointmentsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: AppointmentChronologyResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/patients/${userId}/appointments`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Медкарта
     * @name GetAnalyses
     * @summary Получить данные для анализов в медкарте
     * @request GET:/patients/{userId}/analyses
     * @secure
     */
    getAnalyses: ({ userId, ...query }: GetAnalysesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: AnalysisChronologyResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/patients/${userId}/analyses`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Медкарта
     * @name GetHospitals
     * @summary Получить данные для стационаров в медкарте
     * @request GET:/patients/{userId}/hospitals
     * @secure
     */
    getHospitals: ({ userId, ...query }: GetHospitalsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: HospitalChronologyResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/patients/${userId}/hospitals`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  priceLists = {
    /**
     * No description
     *
     * @tags Прайслисты
     * @name GetPriceLists
     * @summary Получить прайслисты клиники
     * @request GET:/price-lists
     * @secure
     */
    getPriceLists: (query: GetPriceListsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: PriceListResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/price-lists`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  exports = {
    /**
     * No description
     *
     * @tags Зарплаты, Экспорт
     * @name ExportSalaries
     * @summary Экспортировать зарплаты
     * @request GET:/exports/salaries
     * @secure
     */
    exportSalaries: (query: ExportSalariesParams, params: RequestParams = {}) =>
      this.request<ExportSalariesData, any>({
        path: `/exports/salaries`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  services = {
    /**
     * No description
     *
     * @tags Услуги
     * @name GroupServiceListForReferrers
     * @summary Получить услуги для дифф комиссий
     * @request GET:/services/group/list-for-referrers
     * @secure
     */
    groupServiceListForReferrers: (
      query: GroupServiceListForReferrersParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (GroupServiceReferrerResource & {
            category: ServiceCategoryShortResource;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/services/group/list-for-referrers`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
}
